import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material
import { AppMaterialModule } from './app-material.module';

// Pipes
import { FilterPipe } from './pipe/filter.pipe';

// Services
import { AsideService } from './shared/aside.service';
import { ApiService } from './shared/api.service';
import { AuthService } from './shared/auth.service';
import { UiService } from './shared/ui.service';

// Routes
import { routes } from './app.routes';

// App Components
import { AppComponent } from './app.component';
import { AsideComponent } from './aside/aside.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterComponent } from './register/register.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CompleteRegistrationComponent } from './complete-registration/complete-registration.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ResetComponent } from './reset/reset.component';


@NgModule({
  declarations: [
    AppComponent,
    AsideComponent,
    LightboxComponent,
    FilterPipe,
    DashboardComponent,
    LoginComponent,
    PageNotFoundComponent,
    RegisterComponent,
    CalendarComponent,
    CompleteRegistrationComponent,
    ResetComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    routes,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [AsideService, ApiService, AuthService, UiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
