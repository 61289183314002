<mat-sidenav-container fullscreen>
  <mat-sidenav class='mainmenu' #sidenav [mode]="menuside" [opened]="menuopen" position="start">
    <nav>

      <!-- Logged in Profile Details -->
      <!-- ------------------------- -->
      <div class='profile'>
        <div class='profilepic flex'>{{ admininitials }}</div>
        <div class='profiledetails'>
          <span *ngIf="!isUpdatingDetails">{{ administrator }}</span><br *ngIf="!isUpdatingDetails">
          <span *ngIf="!isUpdatingDetails">{{ adminemail }}</span>
          <input #administratorInput [style.display]="isUpdatingDetails ? 'block': 'none'" [(ngModel)]="administrator" type="text" />
          <input #adminemailInput [style.display]="isUpdatingDetails ? 'block': 'none'" [(ngModel)]="adminemail" type="text" />
        </div>
      </div>

      <!-- Menu Items -->
      <div class='sub'>

        <!-- Manage Pages -->
        <!-- ------------ -->
        <!-- <a routerLink="/overview" class="submenu" #updateButton (click)="editDetails()">Update Details</a> -->
        <a routerLink="/overview" class="submenu" (click)="resetPassword()">Reset Password</a>
        <a (click)="logOut()" class="submenu">Logout</a>

      </div>

    </nav>
  </mat-sidenav>

  <app-lightbox *ngIf="lightbox.showLightbox"
    [lightboxTemplate]="lightbox.lightboxTemplate"
    [lightboxInfo]="lightbox.lightboxInfo"
    [lightboxWidth]="lightbox.lightboxWidth"
    (onClose)="lightbox.close()"
    (onSelectInfo)="lightbox.lightboxSelectedInfo.next($event.target)">
  </app-lightbox>

  <mat-toolbar class='header' *ngIf='showheader'>

    <mat-toolbar-row>
      <a href='javascript:void(0);' (click)="closeMenu()" class="closebtn" *ngIf="menubtn"><mat-icon>arrow_left</mat-icon></a>
      <a href='javascript:void(0);' (click)="openMenu()" class="menubtn" *ngIf="!menubtn">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </a>
      <a routerLink='/overview' class='logo'><img src='./assets/img/temp-logo.png'></a>
      <div class="toolbarspacer"></div>
    </mat-toolbar-row>

  </mat-toolbar>

  <router-outlet></router-outlet>

  <mat-sidenav class='aside' #asidenav mode="over" opened="false" position="end" style="height: 100vh; overflow: hidden;">
    <app-aside></app-aside>
  </mat-sidenav>

  <div class="footer" *ngIf='showFooter'>
    <div class="footer-padding">
      <div class="footer-wrapper">
        <div class="footer-left">
          <img src="../assets/img/white-logo.png">
        </div>
        <div class="footer-right">
          106 West 3rd Street <br/>
          P.O. Box 488 <br/>
          McCook <br/>
          NE 69001
        </div>
      </div>
      <div class="footer-copyright">
        <a href="" target="_blank">wellness-partners.org</a> | 877.345.7775
      </div>
    </div>
  </div>

</mat-sidenav-container>
