<!-- loader -->
<div class="matspinner calendarspinner" *ngIf="isLoading" >
  <mat-spinner strokeWidth="6"></mat-spinner>
</div>

<!-- page -->
<div class="calender-page header-space" *ngIf="!isLoading">
  <div class="mobile-frame mobile-frame-padding">

    <!-- header -->
    <div class="header-date">
      <div class="header-month">{{ monthName }}</div>
      <div class="header-year">{{ year }}</div>
    </div>

    <!-- task -->
    <div class="task-wrapper text-center">

      <!-- task header -->
      <div class="task-content" style="margin-bottom: 6px;">This Month’s Challenge</div>

      <!-- task title -->
      <div class="task-title">{{ task }}</div>
      
      <div class="space-15"></div>
      <div class="task-content">
        Mark off each day you complete the challenge, changes will automatically save
      </div>

    </div><!-- task-wrapper -->

    <!-- dates -->
    <div class="dates-wrapper">

      <!-- empty spaces loop -->
      <div class="date-block" *ngFor="let day of [].constructor(getEmptyBlocks()); let i = index"></div>

      <!-- dates main loop -->
      <div class="date-block" *ngFor="let day of [].constructor(days); let i=index" [class.future-date]="isFutureDate(i+1)" [class.just-completed]="submitDay && i === submitDay-1" [class.completed]="isCompletedDate(i+1)" [class.additional-tasks]="additionalTasks > 0">
        <div class="date-block-wrapper" (click)="!isCompletedDate(i+1) ? submitTask(i+1) : unSubmitTask(i+1)">

          <!-- just completed -->
          <!-- <span *ngIf="submitDay && i === submitDay-1; else showDate" class="material-icons">done</span> -->
          <svg *ngIf="submitDay && i === submitDay-1; else showDate" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>

          <ng-template #showDate>
            <ng-container *ngIf="isCompletedDate(i+1); else showPending" >
              <div class="day-date">
                <div class="day">{{ getDay(i+1) }}</div>
                <div class="date">{{ i+1 }}</div>
              </div>
              <span class="material-icons">done</span>
            </ng-container>
            <ng-template #showPending>
              <div class="day">{{ getDay(i+1) }}</div>
              <div class="date">{{ i+1 }}</div>
            </ng-template>
          </ng-template>

        </div>

        <div class="tasks-wrapper" *ngIf="additionalTasks > 0" [style]="getTasksPosition(i+1)">
          <ng-container *ngFor="let task of tasks; let j = index">
            <mat-checkbox *ngIf="task" (change)="submitIndividualTask(i+1, j+1, $event)" [checked]="isChecked(i+1, j+1)">{{ task }}</mat-checkbox>
          </ng-container>
        </div>
      </div>

      <!-- next month preview loop -->
      <ng-container *ngIf="month < 12">
        <div class="date-block future-date" *ngFor="let day of [].constructor(5); let i = index">
          <div class="date-block-wrapper" [style.color]="previewPrimaryColor" [style.border-color]="previewSelectedColor">
            <div class="day">{{ getDay(i+1, true) }}</div>
            <div class="date">{{ i+1 }}</div>
          </div>
        </div>
      </ng-container>

    </div><!-- dates-wrapper -->

    <!-- total -->
    <div class="total-task-block text-center">
      <span class="total-text">TOTAL:</span><span class="task-done"> {{ totalTasksDone }} / </span><span class="total-task">{{ totalTasks }}</span>
    </div>

    <!-- download pdf -->
    <div class="download-pdf-wrapper text-center">
      <div class="download-pdf-content">
        Download the pdf for more information
      </div>
      <div class="download-pdf-btn">
        <a (click)="downloadPdfDoc()">DOWNLOAD PDF</a>
      </div>
    </div>

  </div><!-- mobile-frame mobile-frame-padding -->
</div><!-- calender-page header-space -->
