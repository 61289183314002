<div class="register">
  <div class="register-top bg-color1 text-center mobile-frame-padding">
    <div class="mobile-frame">
      <div class="logo">
        <img src='./assets/img/logo.png'>
      </div>
      <div class="content-and-form" *ngIf="!isLoading">
        <div class="content color-white">
          <div class="heading secondaryfont">
            Welcome!<br>
            {{userName}}
          </div>
          <div class="para">
            You have been invited to our Thrive 10 week journey of health and vitality. This app will allow yourself and us to track your progress.
          </div>
        </div>
        <div class="register-form">
          <div class="form-title color-white">Please set a password for yourself</div>
          <form [formGroup]="registerForm">
            <mat-form-field appearance="outline">
              <input matInput placeholder="SET YOUR PASSWORD" formControlName="password" type="password" />
              <mat-error *ngIf="!!registerForm.get('password').errors?.required">
                Password is required.
              </mat-error>
            </mat-form-field>
            <div class="action-btn">
              <button mat-raised-button type="submit" class='circle-btn buttoncolor3 mainfont' [class.loading]="isSubmitting" [disabled]="registerForm.invalid" (click)="submitPassword()">
                <img class="submit-loader" *ngIf="isSubmitting" src="'../../../../assets/img/submit-3.gif" >
                <span class="btn-text">{{ signinBtn }}</span>
              </button>
              <div class="action-btn-link">
                <a routerLink="/login" >Already have an account? Login here.</a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="matspinner" *ngIf="isLoading" >
        <mat-spinner strokeWidth="6"></mat-spinner>
      </div>
    </div>
  </div>
  <div class="register-bottom bg-white mobile-frame-padding">
    <div class="mobile-frame">
      <div class="quote text-center">“You’ll never change your life until you change something you do daily. The secret of your success is found in your daily routine.” <strong>John C. Maxwell</strong></div>
      <div class="bottom-logo text-center">
        <img src='./assets/img/temp-logo.png'>
      </div>
    </div>
  </div>
</div>
