import { Routes, RouterModule } from "@angular/router";

// App Component.
// -------------
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { RegisterComponent } from "./register/register.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { AuthGuardService as AuthGuard } from './login/shared/auth-guard.service';
import { CompleteRegistrationComponent } from "./complete-registration/complete-registration.component";
import { ResetComponent } from "./reset/reset.component";

export const router: Routes = [

    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'overview', component: DashboardComponent},
    { path: 'month/:month', canActivate: [AuthGuard], component: CalendarComponent },
    { path: 'register/:link', component: RegisterComponent },
    { path: 'register', component: CompleteRegistrationComponent },
    { path: 'forgot-password', component: ResetComponent },
    { path: '**', component: PageNotFoundComponent }

];

export const routes = RouterModule.forRoot(router, { relativeLinkResolution: 'legacy' });
