import { OnInit, Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, RoutesRecognized } from '@angular/router';
import { AsideService } from './shared/aside.service';
import { MatSidenav } from "@angular/material/sidenav";

import { filter } from 'rxjs/operators';

import { ApiService } from './shared/api.service';
import { AuthService } from './shared/auth.service';
import { LightboxService } from './shared/lightbox.service';
import { UiService } from './shared/ui.service';
import { switchMap, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  @ViewChild('asidenav') public asidenav: MatSidenav;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  // Change menuside to 'over' and menuopen to 'false'
  // if you wish to by default hide the menu on the left
  menuside                    = 'over';
  menuopen                    = false;

  // Hide menu button and header
  menubtn                     = false;
  showheader                  = false;
  showFooter                  = false;

  admininitials               = ' ';
  administrator               = ' ';
  adminemail                  = ' ';

  // Public URLs like login
  publicUrl                   = ['/login','/', '/thank-you', '/forgot-password'];

  @ViewChild('administratorInput', {static: false}) administratorInput: ElementRef;
  @ViewChild('adminemailInput', {static: false}) adminemailInput: ElementRef;
  @ViewChild('updateButton', {static: false}) updateButton: ElementRef;
  isUpdatingDetails           = false;

  public constructor(
    public lightbox: LightboxService,
    public aside: AsideService,
    private auth: AuthService,
    private router: Router,
    private renderer: Renderer2,
    private ui: UiService,
    private api: ApiService,
    private elementRef: ElementRef,
    private route: ActivatedRoute ) { }

  ngOnInit(){

    // removed service worker programatically
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
       registration.unregister()
     } });

    // If this is a public page remove the header
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd || e instanceof RoutesRecognized)
      ).subscribe(data => {
        if (data instanceof NavigationEnd) {

          if( this.publicUrl.includes( data['url'] ) || this.router.url.includes('/survey') || this.router.url.includes('/register') ) {
            this.menuopen       = false;
            this.showheader     = false;
            this.showFooter     = false;
            this.menubtn        = false;
          } else {
            if(this.menuside != 'over') {
              this.menuopen     = true;
              this.menubtn      = true;
            } else {
              this.menubtn      = false;
            }
            this.showheader     = true;
            this.showFooter     = true;

            if ( ! this.auth.isLoggedIn ) {
              this.router.navigate(["login"]);
              return  false;
            } else {
              this.administrator  = this.auth.getUserName();
              this.adminemail     = this.auth.getUserEmail();
              let initials        = this.administrator.split(" ");
              let admininitials   = "";
              for(let initial of initials) {
                admininitials    += initial.substring(0, 1).toUpperCase();
              }
              this.admininitials  = admininitials;
            }
          }
        }
        console.log(data);
        if (data instanceof RoutesRecognized && this.auth.isLoggedIn()) {

          let routeMonth              = data.state.root.firstChild.params['month'];
          let date                    = new Date();
          let month                   = date.getMonth() + 1;
          let formattedMonth          = month < 9 ? "0" + month : month;
          let finalValue              = routeMonth ? routeMonth : formattedMonth;

          // calling subscribe within subscribe, will soon add relavant rxjs operator to fix this issue.
          this.api.get('v1/jumpstart/month/' + finalValue)
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.elementRef.nativeElement.style.setProperty('--primarycolor', data.data.primary);
                this.elementRef.nativeElement.style.setProperty('--selectedcolor', data.data.selected);
                this.elementRef.nativeElement.style.setProperty('--hovercolor', data.data.hover);
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
            });

        }
      });

      // name & email outside click
      this.renderer.listen('window', 'click',(e:Event)=> {
        if(e.target !== this.updateButton.nativeElement && e.target !== this.administratorInput.nativeElement && e.target !== this.adminemailInput.nativeElement){
          if(this.isUpdatingDetails) {
            this.updateDetails();
          }
        }
      });

  }

  ngOnViewInit() {
    // Setup the aside drawer
    this.aside.setSidenav(this.asidenav);
  }

  closeMenu() {
    this.sidenav.toggle();
    if(this.menuside != 'over') {
      this.menubtn            = false;
    }
  }

  openMenu() {
    this.sidenav.toggle();
    if(this.menuside != 'over') {
      this.menubtn            = true;
    }
  }

  logOut(){
    this.auth.logout();
    if(this.menuside == 'over') {
      this.sidenav.toggle();
    }
  }

  editDetails() {
    this.isUpdatingDetails           = true;
  }

  updateDetails() {
    this.isUpdatingDetails           = false;

    let administrator                = this.administrator.split(" ");

    const payload = {
      firstName: administrator[0],
      lastName: administrator[1],
      email: this.adminemail
    };

    console.log(payload);

    this.api.put('v1/users/' + this.auth.getUserId() + '/update', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          console.log(data);
          this.ui.showSnackbar("Details updated successfully", "success");
          this.auth.setUserName(this.administrator);
          this.auth.setUserEmail(this.adminemail);
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
      });

  }

  resetPassword() {
    this.ui.showSnackbar("Sending resetting mail... please wait", "pending");

    const payload = {
      userId: this.auth.getUserId()
    };
    console.log(payload);
    this.api.post('v1/jumpstart/users/sendResetPasswordEmail', payload) 
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.auth.logout();
          this.ui.showSnackbar('Thank you. Please check your email for a reset link.', 'success');

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });
  }

}
