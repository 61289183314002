import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { UiService } from '../shared/ui.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  // loading boolean
  isLoading:boolean               = true;

  // progress array
  progress                        = [];

  // current month
  month:any;

  // current day
  day:any;

  // month name
  monthName:String;

  // days
  days:number;

  // current year
  year:number;

  // preview date
  previewDate;

  // submit
  isSubmitting:boolean            = false;
  submitDay:number;

  // details
  task;
  tasks:any                       = [];
  additionalTasks                 = 0;
  primaryColor;
  selectedColor;
  hoverColor;
  pdfFile;
  totalTasks;
  totalTasksDone;

  // preview month
  previewPrimaryColor;
  previewSelectedColor;
  previewHoverColor;

  public innerWidth: any;
  emptyBlocks                     = 0;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private ui: UiService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {

    // set year, month & day
    this.setVariables();

    // fetch data from server
    this.fetchData();

    this.innerWidth  = window.innerWidth;
    this.emptyBlocks = this.getEmptyBlocks();
  }

  // fetch data
  fetchData() {

    let month           = this.updateMonth(this.month);

    let progress        = this.api.get('v1/jumpstart/user/' + this.auth.getUserId() + '/year/' + this.year + '/month/' + month + '/progress');
    let details         = this.api.get('v1/jumpstart/month/' + this.month);
    let preview         = this.api.get('v1/jumpstart/month/' + (+this.month+1));

    // make sure month is less then 12.
    let join            = [progress, details];
    if(+this.month < 12) join.push(preview);

    forkJoin(join)
      .subscribe(results => {
        console.log(results);
        if(results[0].status == "success" && results[1].status == "success") {

          this.progress                 = results[0].data;
          this.task                     = results[1].data.task;

          let tasks                     = results[1].data.tasks;
          this.tasks                    = Object.keys(tasks).map((key) => tasks[key]);

          let extraTasks                = 0;
          this.tasks.forEach((task) => {
            if(task != null) { extraTasks++; }
          });
          this.additionalTasks          = extraTasks;

          console.log(this.tasks);
          this.primaryColor             = results[1].data.primary;
          this.selectedColor            = results[1].data.selected;
          this.hoverColor               = results[1].data.hover;
          this.pdfFile                  = results[1].data.pdfFile;
          this.elementRef.nativeElement.style.setProperty('--primarycolor', this.primaryColor);
          this.elementRef.nativeElement.style.setProperty('--selectedcolor', this.selectedColor);
          this.elementRef.nativeElement.style.setProperty('--hovercolor', this.hoverColor);
          this.totalTasksDone           = this.progress.length;
          if(results[2]) {
            this.previewPrimaryColor     = results[2].data.primary;
            this.previewSelectedColor    = results[2].data.selected;
            this.previewHoverColor       = results[2].data.hover;
          }
          this.isLoading               = false;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
    }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });

  }

  // set variables
  setVariables() {
    // ======= testing feature ========
    let preview                   = this.route.snapshot.queryParamMap.get('preview');
    if(preview) {
      let day                     = this.route.snapshot.queryParamMap.get('day');
      let date                    = new Date(day);
      this.previewDate            = moment(date);
      this.year                   = date.getFullYear();
      let month                   = date.getMonth() + 1;
      this.month                  = month < 9 ? "0" + month : month;
      let currentDay              = date.getDate();
      this.day                    = currentDay < 9 ? "0" + currentDay : currentDay;
      this.monthName              = this.getMonth(this.month);
      this.days                   = this.getNumberOfDays(this.month);
      this.totalTasks             = currentDay;
    } else {
       // current year
      this.year                    = new Date().getFullYear();

      // get month number from route
      this.month                   = this.route.snapshot.paramMap.get('month');

      // setup day
      let day                      = new Date().getDate()
      this.day                     = day < 9 ? "0" + day : day;

      // total tasks
      this.totalTasks              = day;

      // fetch month name
      this.monthName               = this.getMonth(this.month);

      // fetch number of days
      this.days                    = this.getNumberOfDays(this.month);
    }
  }

  // submit
  submitTask(day:number) {

    // change date bg color
    this.submitDay              = day;

    // submit boolean
    this.isSubmitting           = true;

    let currentDate             = moment(new Date(new Date(  +this.year , +this.month - 1 , +day).setHours(0,0,0,0)).toISOString()).format('YYYY-MM-DD');

    // payload
    const payload               = {
      day: currentDate,
      progress: 'complete'
    }

    console.log(payload);

    this.api.post('v1/jumpstart/user/' + this.auth.getUserId() + '/progress', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          // change bg color after 10 second
          setTimeout(() => {
            this.isSubmitting     = false;
            this.submitDay        = null;
          }, 10000);

          this.fetchData();

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
      });

  }

  unSubmitTask(day: number) {

    // change date bg color
    // this.submitDay                = day;

    let currentDate               = moment(new Date(new Date(  +this.year , +this.month - 1 , +day).setHours(0,0,0,0)).toISOString()).format('YYYY-MM-DD');

    // payload
    const payload                 = {
      day: currentDate,
      progress: 'incomplete'
    }

    console.log(payload);

    this.api.post('v1/jumpstart/user/' + this.auth.getUserId() + '/progress', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          // change bg color after 10 second
          setTimeout(() => {
            this.isSubmitting     = false;
            this.submitDay        = null;
          }, 10000);

          this.fetchData();

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
      });

  }

  // submit
  submitIndividualTask(day:number, task:number, event) {

    // change date bg color
    this.submitDay                = day;

    let currentDate               = moment(new Date(new Date(  +this.year , +this.month - 1 , +day).setHours(0,0,0,0)).toISOString()).format('YYYY-MM-DD');

    this.isSubmitting             = true;

    // payload
    const payload                 = {
      day: currentDate,
      task: task,
      value: event.checked
    }

    console.log(payload);

    this.api.put('v1/jumpstart/user/' + this.auth.getUserId() + '/progress/individual', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          // change bg color after 0.5 second
          setTimeout(() => {
            this.isSubmitting     = false;
            this.submitDay        = null;
          }, 1000);

          let progressIndex      = this.progress.findIndex((obj) => { return obj.id == data.data.id; });
          if(progressIndex != -1) {
            this.progress.splice(progressIndex, 1, data.data);
          } else {
            this.progress.push(data.data);
          }
          console.log(this.progress);
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
    }, err => {
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });

  }

  // get month name
  getMonth(month:number) {
    const months                 = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[month-1];
  }

  // fetch month days
  getNumberOfDays(month:number) {
    return new Date(this.year, month, 0).getDate();
  }

  // get week day
  getDay(day:number, showPreview?:boolean) {
    const days                  = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    if(showPreview) {
      return days[new Date(this.year,this.month, day).getDay()];
    } else {
      return days[new Date(this.year,this.month-1, day).getDay()];
    }
  }

  // check if future date
  isFutureDate(day:number) {
    let today                   = new Date().getTime();
    if(this.previewDate) {
      today                     = new Date(this.previewDate).getTime();
    }
    let currentDay              = new Date(this.year,this.month-1, day).getTime();
    if(currentDay > today) {
      return true;
    }
    return false;
  }

  // check if completed date
  isCompletedDate(day:number) {

    let date                    = this.progress.find((obj) => {
      let dateArray             = obj.day.split('-');
      return dateArray[2] == day;
    });

    if(date) {
      return true;
    }
    return false;

  }

  // get number of empty blocks before starting real loop
  getEmptyBlocks() {
    let current = new Date(this.year,this.month-1, 1).getDay();
    return current;
  }

  isChecked(day:number, task:number) {
    let dayExists = this.progress.find((obj) => {
      let dateArray             = obj.day.split('-');
      return dateArray[2] == day;
    });
    if(dayExists) {
      let taskValue;
      if(task == 1) taskValue   = dayExists.task1;
      if(task == 2) taskValue   = dayExists.task2;
      if(task == 3) taskValue   = dayExists.task3;
      if(task == 4) taskValue   = dayExists.task4;
      if(task == 5) taskValue   = dayExists.task5;
      if(task == 6) taskValue   = dayExists.task6;
      if(task == 7) taskValue   = dayExists.task7;
      return taskValue === 'complete' ? true : false;
    }
    return false;
  }

  getTasksPosition(day) {
    let styles  = {};
    if(this.innerWidth <= 720 && this.innerWidth > 480) {
      let block = this.emptyBlocks + day;
      if(block % 7 == 0) {
        styles = { "right": '0px' }
      }
      // 1, 8, 15
      if((block - 1) % 7 == 0) {
        styles = { "left": '0px' }
      }
    }
    if(this.innerWidth <= 480) {
      let block = this.emptyBlocks + day;
      if(block % 4 == 0) {
        styles = { "right": '0px' }
      }
      // 1, 5, 9
      if((block - 1) % 4 == 0) {
        styles = { "left": '0px' }
      }
    }
    return styles;
  }

  downloadPdfDoc() {
    if(this.pdfFile) {
      location.href   = "https://api.wellness-partners.org/export.php?file=" + this.pdfFile;
      return;
    }
    return false;
  }
  
  updateMonth(month) {
    // if(month < 10) {
    //   return "0" + month.toString();
    // } else {
      return month;
    //}
  }
}
