import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { UiService } from '../shared/ui.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  signinBtn       = 'Sign in';
  isSubmitting    = false;
  registerForm: FormGroup;
  resetlink;
  isLoading       = true;
  userName;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private ui: UiService,
    private router: Router,
    private activeroute: ActivatedRoute
  ) { }

  ngOnInit() {

    this.resetlink        = this.activeroute.snapshot.params['link'];

    this.registerForm = this.fb.group({
      password: ['', Validators.required]
    });

    this.api.get('v1/users/' + this.resetlink + '/checklink')
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.userName   = data.username;
          this.isLoading  = false;
        } else {
          this.ui.showSnackbar(data.message, "failure");
          this.router.navigate(['/login']);
        }
    }, err => {
      this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });

  }

  submitPassword() {

    this.ui.showSnackbar("Setting your password... Please wait" , "pending");
    this.isSubmitting           = true;

    const payload = {
      registrationDate: Math.floor(new Date().getTime()/1000),
      resetLink: this.resetlink,
      password: this.registerForm.controls.password.value
    };
    console.log(payload);
    this.api.put('v1/users/setpassword', payload)
      .subscribe(data => {

        if(data.status == "success") {

          this.auth.setToken(data.token);
          this.auth.setUserId(data.userId);
          this.auth.setUserScope(data.userScope);
          this.auth.setUserName(data.userName);
          this.auth.setUserEmail(data.userEmail);

          this.router.navigate(['/month/' + this.getCurrentMonth()]);

          this.isSubmitting           = false;
          this.ui.showSnackbar("Logged in successfully!" , "success");

        } else {
          this.isSubmitting           = false;
          this.ui.showSnackbar(data.message, "failure");
        }
    }, err => {
      this.isSubmitting           = false;
      this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });

  }

  getCurrentMonth() {
    let month = new Date().getMonth() + 1;
    return month < 9 ? "0" + month : month;
  }

}
