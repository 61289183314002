import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { UiService } from '../shared/ui.service';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {

  signinBtn       = 'Sign in';
  isSubmitting    = false;
  registerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private ui: UiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      group: ['', [Validators.minLength(4), Validators.maxLength(4)]]
    });
  }

  submit() {

    this.isSubmitting           = true;

    let name                    = this.registerForm.controls.name.value.split(" ");
    let firstName               = null;
    let lastName                = null;
    if(name.length > 1) {
      firstName                 = name.slice(0, -1).join(" ");
      lastName                  = name.splice(-1).join(" ");
    } else {
      firstName                 = name.join(" ");
    }
    console.log(name);
    const payload = {
      registrationDate: Math.floor(new Date().getTime()/1000),
      firstName: firstName,
      lastName: lastName,
      email: this.registerForm.controls.email.value,
      password: this.registerForm.controls.password.value,
      group: this.registerForm.controls.group.value,
    };

    console.log(payload);
    this.api.post('v1/completeregistration', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.auth.setToken(data.token);
          this.auth.setUserId(data.userId);
          this.auth.setUserScope(data.userScope);
          this.auth.setUserName(data.userName);
          this.auth.setUserEmail(data.userEmail);
          this.router.navigate(['/month/' + this.getCurrentMonth()]);
          this.isSubmitting           = false;

        } else {
          this.isSubmitting           = false;
          this.ui.showSnackbar(data.message, "failure");
        }
    }, err => {
      this.isSubmitting           = false;
      this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });

  }

  getCurrentMonth() {
    let month = new Date().getMonth() + 1;
    return month < 9 ? "0" + month : month;
  }

}
