import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from '../shared/api.service';
import { UiService } from '../shared/ui.service';
import { AuthService } from '../shared/auth.service';
import { LoginService } from './shared/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  // Loading booleans
  isSubmitting              = false;

  // Template dynamic content
  loginbtn                  = 'Login';

  redirectUrl;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private ui: UiService,
    private router: Router,
    private loginService: LoginService ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email:    ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.loginService.getRedirectUrl()
          .subscribe(url => {
            this.redirectUrl  = url;
    });
  }

  submitLogin() {
    const payload = this.loginForm.value;

    // Update button and snackbar

    this.isSubmitting           = true;
    this.ui.showSnackbar("Logging in... Please wait", "pending");

    this.api.post('v1/login', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          // Authenticate the user
          this.auth.setToken(data.token);
          this.auth.setUserId(data.userId);
          this.auth.setUserScope(data.userScope);
          this.auth.setUserName(data.userName);
          this.auth.setUserEmail(data.userEmail);

          if(this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
          } else {
             this.router.navigate(['/month/' + this.getCurrentMonth()]);
          }


          // Update button and snackbar

          this.ui.showSnackbar("Logged in successfully", "success");

        } else {
          this.isSubmitting     = false;
          this.ui.showSnackbar("Sorry username or password is incorrect.", "failure");
        }
      }, err => {
        console.log(err.statusCode);
        if(err.statusCode == 401) {
          this.isSubmitting     = false;
          this.ui.showSnackbar("Sorry username or password is incorrect.", "failure");
        } else {
          this.isSubmitting     = false;
          this.ui.showSnackbar("Network Error. Please check your internet connection", "failure");
        }
      });

  }

  getCurrentMonth() {
    let month = new Date().getMonth() + 1;
    return month < 9 ? "0" + month : month;
  }
}
