<div class="register login-page">
  <div class="register-top bg-color1 text-center mobile-frame-padding">
    <div class="mobile-frame">
      <div class="logo">
        <img src='./assets/img/logo.png'>
      </div>
      <div class="register-form">
        <form [formGroup]="loginForm">
          <mat-form-field appearance="outline">
            <input matInput placeholder="EMAIL ADDRESS" formControlName="email" spellcheck="false" />
            <mat-error *ngIf="!!loginForm.get('email').errors?.required">
              Email is required.
            </mat-error>
            <mat-error *ngIf="!!loginForm.get('email').errors?.email">
              Invalid Email, Valid format is <strong>name@company.abc</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <input matInput placeholder="PASSWORD" formControlName="password" type="password" />
            <mat-error *ngIf="!!loginForm.get('password').errors?.required">
              Password is required.
            </mat-error>
          </mat-form-field>
          <div class="action-btn">
            <button mat-raised-button type="submit" class='circle-btn buttoncolor3 mainfont' [class.loading]="isSubmitting" [disabled]="loginForm.invalid" (click)="submitLogin()">
              <img class="submit-loader" *ngIf="isSubmitting" src="'../../../../assets/img/submit-3.gif" >
              <span class="btn-text">{{ loginbtn }}</span>
            </button>
            <div class="action-btn-link">
              <a routerLink="/register">No account? Register here.</a>
            </div>
            <div class="action-btn-link forgot-password">
              <a routerLink="/forgot-password">Forgot password?</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="register-bottom bg-white mobile-frame-padding">
    <div class="mobile-frame">
      <div class="quote text-center">“You’ll never change your life until you change something you do daily. The secret of your success is found in your daily routine.” <strong>John C. Maxwell</strong></div>
      <div class="bottom-logo text-center">
        <img src='./assets/img/temp-logo.png'>
      </div>
    </div>
  </div>
</div>
