import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../shared/api.service';
import { UiService } from '../shared/ui.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  resetForm: FormGroup;

  // Loading booleans
  isSubmitting              = false;

  // Template dynamic content
  resetbtn                  = 'Reset';

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private ui: UiService,
  ) { }

  ngOnInit() {
    this.resetForm = this.fb.group({
      email:    ['', [Validators.required, Validators.email]],
    });
  }

  resetLogin() {
    const payload = this.resetForm.value;
    this.resetbtn               = 'Resetting';
    this.isSubmitting           = true;

    this.api.post('v1/jumpstart/users/resetPassword', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.isSubmitting     = false;
          this.resetbtn         = 'Reset';
          this.ui.showSnackbar('Reset mail sent successfully!', 'success');
        } else if(data.status == 'usernotexists') {
          this.isSubmitting     = false;
          this.resetbtn         = 'Reset';
          this.ui.showSnackbar("Sorry. User doesn't exists.", "failure");
        } else {
          this.isSubmitting     = false;
          this.resetbtn         = 'Reset';
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.isSubmitting       = false;
        this.resetbtn           = 'Reset';
        this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
    });

  }

}
