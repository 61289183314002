import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private redirectUrl         = new BehaviorSubject(null);
  constructor() { }

  setRedirectUrl(val) {
    this.redirectUrl.next(val);
  }

  getRedirectUrl() {
    return this.redirectUrl.asObservable();
  }

}
